.bg-blog{

  
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  
}
.blog-head-h>h1 {
  font-size: 30px;
  text-transform: uppercase;
}
.blog-head-h{

padding: 10px 0px;

}
.Serial-no-h1{

  padding: 20px 0px;
}
.Serial-no-h1>h1{

  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.blog-data-b{

  padding: 10px;
  display: flex;
  justify-content: center;
 flex-wrap: wrap;
}
  
/* vlog-css */

.blog-flex-slider-data {
  display: flex;
}
.bg-blog {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  /* min-height: 618px; */
}
.blog-head-data {
  padding: 100px 0px 0px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  
}
.data-blog1 {

    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.blog-content1 {
  border: 1px solid;
  padding: 30px 30px 30px 30px;
  background: white;
  border-radius: 6px;
  
}
.head-h-b > h1 {
  cursor: pointer;
  font-size: 28px;
  text-transform: uppercase;
  width: fit-content;
}
.head-h-p > p {
  font-size: 16px;
  padding: 10px 0px;
}
.head-h-b > h1:hover {
  color: #bc0000;
}
.blog-content1:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.head-gap-blog{

    display: flex;
    gap: 2rem;
    flex-direction: column;
}
/*  */