
.pdfflip{
    width: 100%;
display: flex;
justify-content: center;
padding-bottom: 100px;
overflow-y: hidden;
flex-direction: column;
align-items: center;
}


.pdfflip::-webkit-scrollbar{
display: none;
}


.demoPage img{
height: 100%;
    width: 100%;

}


.imageScrollCaseStudy{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageScrollCaseStudy img{
    width: 60vw;
}
.imgg-case-ss{
    width: 100%;
    padding: 100px 0px 0px 0px;  
}
.section1-im1{
    /* height: 418px; */

    padding: 100px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}