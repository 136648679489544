.case-c {
    background-image: url("../../Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
  }
  
  .contact-h1 {
    font-size: 20px;
  }
  .contact-h2 {
    font-size: 20px;
  }
  .contact-h3 {
    font-size: 20px;
  }
  .contact-section1 {
    /* height: 418px; */
   
  
    padding: 100px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .head-pp {
    width: 90%;
  }
  .contact-section2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  
    gap: 3rem;
  }
  /* .load{
      border: 16px solid black;
      border-radius: 50%;
      border-top: 16px solid white;
      width: 120px;
      height: 120px;
      animation:  rotate 2s linear infinite;
  
  }
  @keyframes rotate{
      0%{transform: rotate(0deg);}
      100%{transform: rotate(360deg);}
  
  
  } */
  
 
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  
    text-transform: uppercase;
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }
  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color:white;
    font-size: 12px;
  }
  
  .login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color:white;
  
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 40px;
    letter-spacing: 4px;
  }
  
  /* .login-box a:hover {
    background:white;
    color:black;
    border-radius: 5px; */
    /* box-shadow: 0 0 5px#bc0000, 0 0 25px#bc0000, 0 0 50px#bc0000, 0 0 100px#bc0000; */
  /* } */
  /* 
  .login-box a span {
    position: absolute;
    display: block;
  }
  
  .login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, white);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  
  .login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent,white);
    animation: btn-anim2 1s linear infinite;
    animation-delay: 0.25s;
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  
  .login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, white);
    animation: btn-anim3 1s linear infinite;
    animation-delay: 0.5s;
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  
  .login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, white);
    animation: btn-anim4 1s linear infinite;
    animation-delay: 0.75s;
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  } */
  
  .contact-us-form {
    display: flex;
    justify-content: center;
    /* padding: 30px 0px; */
  }
  .user-box>textarea{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    box-sizing: border-box;
  
  }
  .user-box>textarea:focus{
    border: none;
    outline-width: 0;
    resize: none;
  }

  .submit-btn-contact:active{
    transform: scale(0.9);
    transition: all 0.5;
  }
  .div-btn-sub{
  
    display: flex;
    justify-content: center;
  }
  .user-box input:focus {
    background-color: transparent;
  }
  
  .user-box>input[type="number"] {
      appearance: none !;
  }








  .contact-us-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .box{
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 15px;
    margin: 0 auto;
  }
  .card{
    cursor: pointer;
    position: relative;
    width: 300px;
    height: 350px;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow:0 2px 10px rgba(0,0,0,.2);
  }
  .card:before,
  .card:after
  {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    transition: 0.5s;
    z-index:-1;
  }

  
  .card .imgBx{
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
  }
  


  .card .imgBx img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
     
  }

  .card .details{
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      height: 60px;
      text-align: center;
  }

  .card .details h2{
   margin: 0;
   padding: 0;
   font-weight: 600;
   font-size: 20px;
   color: #777;
   text-transform: uppercase;
  } 

  .card .details h2 span{
  font-weight: 500;
  font-size: 16px;
  color: #f38695;
  display: block;
  margin-top: 5px;
   } 
   .head-case{
    font-size: 26px;
    letter-spacing: 5px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
   }