.course-bg{
    background-image: url('../../Img/20210204_a547.jpg');
background-position: center;
background-size: cover;
height: 100vh;

}

.course-h1{
    padding: 20px 0px;
    color: #0072BB;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}
.pdf-about-pages>img{
    width: 500px;

}
.pdf-about-pages{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 2rem;
/* width: 40%; */
}
.pdf-logo{
    position: fixed;
    bottom: 100px;
    right: 30px;
    width: 52px;
    /* margin: 50px; */
}
@media only screen and (max-width: 600px) {

.pdf-logo{
    position: fixed;
    bottom: 100px;
    right: 30px;
    width: 52px;
    /* margin: 50px; */
    z-index: 10000;
}
}
.svg-pdf{
    background: white;
    color: black;
    border: 1px solid;
    border-radius: 30px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.flx-cor{
    flex-direction: column;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px;
    border-radius: 24px;
}

.course-h2{

    
    color: #0072BB;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
}
.course-info{

    padding: 100px 0px 0px 0px;
}

/* download */


.pdfflip{
    width: 100%;
display: flex;
justify-content: center;
padding-bottom: 100px;
overflow-y: hidden;
flex-direction: column;
align-items: center;
}


.pdfflip::-webkit-scrollbar{
display: none;
}


.demoPage img{
height: 100%;
    width: 100%;

}
.h1swipe{
    margin-top: 30px;
}